
import Detail from "./detail"
import {
    defineComponent
} from "vue";

export default defineComponent({

    components: { Detail },
    props: {
        setting: {
            type: Object,
            default: () => ({ id: 0, visible: false }),
        }
    },
    setup(props, { emit }) {


        return {

        };
    },
});
