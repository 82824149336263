<template>
  <Base titleIcon="el-icon-s-claim">
  <div style="text-align: right">
    <el-button type="primary" icon="el-icon-plus" v-if="user.inRoles(roles.applyUnit) && activeName != 'apply'"
      @click="$router.push({ name: `ReportAndReview${prefix}SetupManage` })">创建计划书</el-button>

    <el-button type="danger" icon="el-icon-plus" v-if="user.inRoles(roles.city) &&
      user.inRoles(roles.review) &&
      activeName == 'apply'
      " @click="createFileSign">
      创建汇总申请</el-button>
  </div>
  <!-- <div style="text-align: right; margin-bottom: 5px">
      <el-button type="primary" style="margin-left: 5px">导出</el-button>
    </div>-->

  <el-tabs @tab-click="() => {
      if (activeName != 'all') {
        if (activeName != 'apply') {
          filter.status = parseInt(activeName);
          filter.pageNum = 1;
          query();
        } else {
          doQueryFileSign();
        }
      } else {
        filter.status = null;
        filter.pageNum = 1;
        query();
      }

      $router.replace({
        query: Object.assign({}, $route.query, { tab: activeName }),
      });
    }
      " type="card" v-model="activeName">
    <el-tab-pane :label="`全部`" name="all" v-if="user.inRoles(roles.apply, roles.prov)"></el-tab-pane>
    <el-tab-pane :label="`草稿`" name="0" v-if="user.inRoles(roles.applyUnit)"></el-tab-pane>
    <el-tab-pane v-if="user.inRoles(roles.city)" :label="`市级审核`" name="1"></el-tab-pane>
    <el-tab-pane :label="`省级批复`" name="2" v-if="user.inRoles(roles.prov)"></el-tab-pane>
    <!-- <el-tab-pane :label="`方案上传`" name="3" v-if="user.inRoles(roles.prov)"></el-tab-pane>
    <el-tab-pane :label="`已备案`" name="4" v-if="user.inRoles(roles.prov, roles.apply, roles.city)"></el-tab-pane> -->
    <el-tab-pane :label="`汇总申请`" name="apply" v-if="user.inRoles(roles.prov, roles.city)">
      <p>
        <el-space>
          <el-select v-model="fileSignfilter.yearBatch" placeholder="年度批次" clearable>
            <el-option :label="item.yearBatch" :value="item.yearBatch" :key="item.yearBatch"
              v-for="item in setupProjectYearBatch"></el-option>
          </el-select>

          <el-select v-model="fileSignfilter.city" placeholder="市级" clearable v-if="user.inRoles(roles.prov)">
            <el-option v-for="item in dicCity" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>

          <el-select v-model="fileSignfilter.status" placeholder="状态" clearable>
            <template v-for="(item, index) in setupProjectFileSignStatusText" :key="index">
              <el-option :value="index" :label="item" v-if="index > 0 && (user.inRoles(roles.city) || index > 1)">
              </el-option>
            </template>
          </el-select>
          <el-button type="primary" @click="doQueryFileSign()">查询</el-button>
          <el-popover placement="bottom" trigger="hover">
            <template #reference>
              <el-button type="primary">导出申报总表</el-button>
            </template>
            <el-space>
              <el-select v-model="downloadYearBatch" placeholder="年度批次">
                <el-option :label="item.yearBatch" :value="item.yearBatch" :key="item.yearBatch"
                  v-for="item in setupProjectYearBatch"></el-option>
              </el-select>
              <el-button icon="el-icon-download" type="text" @click="downloadProvList(downloadYearBatch)">下载</el-button>
            </el-space>
          </el-popover>

          <el-popover placement="bottom" trigger="hover" v-if="user.inRoles(roles.prov)">
            <template #reference>
              <el-button type="primary">附件及申报表</el-button>
            </template>
            <el-space>
              <el-select v-model="downloadYearBatch" placeholder="年度批次">
                <el-option :label="item.yearBatch" :value="item.yearBatch" :key="item.yearBatch"
                  v-for="item in setupProjectYearBatch"></el-option>
              </el-select>
              <el-button icon="el-icon-download" type="text" @click="downloadPackList(downloadYearBatch)">下载</el-button>
            </el-space>
          </el-popover>

        </el-space>
      </p>

      <el-table :data="signList" v-loading="isLoading" @expand-change="expandChange">
        <el-table-column type="index" width="50" />
        <el-table-column type="expand">
          <template #default="{ row: item }">
            <el-table :data="signListProjects[item.id]" v-loading="isActionLoading">
              <el-table-column label="编号" prop="code"></el-table-column>
              <el-table-column label="地区" prop="areaName"></el-table-column>
              <el-table-column label="名称" prop="project_Name" width="400">
                <template #default="{ row: item }">
                  <router-link :to="{
      name: `ReportAndReview${prefix}SetupView`,
      params: { id: item.id },
      query: {
        redirect: `/ReportAndReview/${prefix}Setup?tab=${activeName}`,
      },
    }">
                    {{ item.project_Name }}</router-link>
                </template>
              </el-table-column>
              <el-table-column label="工程类型" prop="projectType">
                <template #default="{ row: item }">
                  {{
      item.isSafeProject == 1
        ? dicLabel(item.safeProjectType, scheduleTypeDic)
        : dicLabel(item.projectType, scheduleTypeDic)
    }}
                </template>
              </el-table-column>
              <el-table-column label="文保单位" prop="heritageUnitName">
                <template #default="{ row: item }">
                  <a href="javascript:void(0)" @click="lnkToDetail(item.heritageUnit_Id)">{{ item.heritageUnitName }}</a>
                </template>
              </el-table-column>
              <el-table-column label="文保单位类型" prop="heritageUnitClassName">
              </el-table-column>
              <el-table-column label="是否革命文物" prop="heritageUnit_Name">
                <template #default="{ row: item }">
                  {{ item.isRevolution ? "是" : "否" }}
                </template>
              </el-table-column>
              <el-table-column label="状态">
                <template #default="{ row: item }">{{
      setupProjectStatusText[item.status]
    }}</template>
              </el-table-column>
              <el-table-column label="编制人" prop="createUserName"></el-table-column>


              <el-table-column label="下载">
                <template #default="{ row: item }">
                  <el-button type="text" icon="el-icon-download" @click="downloadWord(item)">申报表</el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column label="申报年份" prop="yearBatch"></el-table-column>
        <el-table-column label="市级" prop="areaName"> </el-table-column>
        <el-table-column label="申报文件" prop="申报文件">
          <template #default="{ row: item }">
            <div>
              <el-link :href="i.filePath" v-for="i in item.attachList" :key="i.id" target="_blank"
                :download="i.fileName">{{ i.fileName }}
              </el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="批准文件" prop="批准文件">
          <template #default="{ row: item }">
            <div v-if="item.setupDoc_Id">
              <ShowRelBtn :id="item.setupDoc_Id" @link="lnkToDocContent">
                <i class="el-icon-link"></i>
                {{ item.setupDoc_ComplieNo }}
              </ShowRelBtn>

            </div>
            <div v-else>未上传</div>
          </template>
        </el-table-column>
        <el-table-column label="下载" align="center">
          <template #default="{ row: item }">
            <el-button type="text" icon="el-icon-download" @click="downloadList(item, 1)">革命文物({{ item.revolutionCount
              }})
            </el-button>
            <el-button type="text" icon="el-icon-download" @click="downloadList(item, 0)">
              非革命文物({{ item.notRevolutionCount }})</el-button>

            <el-button icon="el-icon-download" type="text"
              @click="downloadPackList(item.yearBatch, item.id)">附件及申报表</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column label="批准文件" width="600">
          <template #default>
            <officialDocSelectAndCreate :category="3"></officialDocSelectAndCreate>

          </template>
        </el-table-column> -->
        <el-table-column label="状态" prop="status">
          <template #default="{ row: item }">
            {{ setupProjectFileSignStatusText[item.status] }}
          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template #default="{ row: item }">
            <el-button type="text" icon="el-icon-edit" @click="
      $router.push({
        name: 'ReportAndReviewSetupFileSignManage',
        params: { id: item.id },
        query: {
          redirect: `/ReportAndReview/${activeName}Setup?tab=activeName`,
        },
      })
      " v-if="item.status == 1 && item.applyUnitId == user.id">修改
            </el-button>
            <el-popconfirm title="确认删除吗！？删除后不可恢复！" @confirm="doDeleteFileSign(item.id)" placement="top"
              v-if="item.status == 1 && item.applyUnitId == user.id">
              <template #reference>
                <el-button type="text" icon="el-icon-delete">删除 </el-button>
              </template>
            </el-popconfirm>

            <el-button type="text" icon="el-icon-check" @click="backSubmit(item)"
              v-if="item.status == 2 && user.inRoles(roles.prov) && user.inRoles(roles.review)">审核
            </el-button>
            <el-button type="text" icon="el-icon-copy-document" @click="doQueryHistory(item.id)">日志
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="操作日志" @close="logs = []" v-model="showLogHistory">
        <LogHistory :data="logs"></LogHistory>
      </el-dialog>
      <!-- <Pager :pager="pager" @change="query()"></Pager> -->
    </el-tab-pane>
  </el-tabs>

  <template v-if="activeName != 'apply'">
    <p>
      <el-space>
        <el-select v-model="filter.yearBatch" placeholder="年度批次" clearable>
          <el-option :label="item.yearBatch" :value="item.yearBatch" :key="item.yearBatch"
            v-for="item in setupProjectYearBatch"></el-option>
        </el-select>

        <el-select v-model="filter.status" placeholder="状态" clearable v-if="activeName == 'all'">
          <el-option v-for="(item, index) in setupProjectStatusText" :key="index" :value="index" :label="item">
          </el-option>
        </el-select>
        <el-select v-model="filter.city" placeholder="市级" clearable v-if="user.inRoles(roles.prov)">
          <el-option v-for="item in dicCity" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
        <el-select v-model="filter.town" placeholder="区县" clearable v-if="user.inRoles(roles.city, roles.prov)">
          <el-option v-for="item in dicTown" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>

        <el-select v-model="filter.isSafeProject" placeholder="类型" clearable @clear="filter.isSafeProject = null"
          @change="filter.projectType = null">
          <el-option label="保护工程" :value="0"></el-option>
          <el-option label="安防消防防雷" :value="1"></el-option>
          <el-option label="考古调查勘探" :value="2"></el-option>
        </el-select>

        <el-select v-model="filter.projectType" placeholder="工程类型" clearable @clear="filter.projectType = null"
          v-show="filter.isSafeProject != null">
          <el-option v-for="item in scheduleTypeDic.filter(
      (p) => p.ext.projectType == filter.isSafeProject
    )" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

        <el-select v-model="filter.isRevolution" placeholder="是否革命文物" clearable @clear="filter.isRevolution = null">
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
        <el-select v-model="filter.heritageUnitClass" placeholder="文保单位类型" clearable
          @clear="filter.heritageUnitClass = null">
          <el-option v-for="item in dicHeritageClass" :key="item.value" :value="item.value" :label="item.label">
          </el-option>
        </el-select>

        <el-input v-model="filter.keyword" placeholder="关键字，编号，名称，文保单位，编制人"></el-input>

        <el-button type="primary" @click="query">查询</el-button>
        <el-button type="primary" @click="downloadListExcel">导出当前列表</el-button>
      </el-space>
    </p>
    <el-table :data="pagerData.list" v-loading="isLoading">
      <el-table-column label="编号" prop="code"></el-table-column>
      <el-table-column label="地区" prop="areaName"></el-table-column>
      <el-table-column label="名称" prop="project_Name" width="400">
        <template #default="{ row: item }">
          <router-link :to="{
      name: `ReportAndReview${prefix}SetupView`,
      params: { id: item.id },
      query: { redirect: `/ReportAndReview/${prefix}Setup?tab=${activeName}`  },
    }">
            {{ item.project_Name }}</router-link>
        </template>
      </el-table-column>
      <el-table-column label="工程类型" prop="projectType">
        <template #default="{ row: item }">
          {{
      item.isSafeProject == 1
        ? dicLabel(item.safeProjectType, scheduleTypeDic)
        : dicLabel(item.projectType, scheduleTypeDic)
    }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="年份" prop="project_Year"></el-table-column> -->
      <!-- <el-table-column label="地区" prop="areaName"></el-table-column> -->
      <el-table-column label="文保单位" prop="heritageUnitName">
        <template #default="{ row: item }">
          <a href="javascript:void(0)" @click="lnkToDetail(item.heritageUnit_Id)">{{ item.heritageUnitName }}</a>
        </template>
      </el-table-column>
      <el-table-column label="文保单位类型" prop="heritageUnitClassName">
      </el-table-column>
      <el-table-column label="是否革命文物" prop="heritageUnit_Name">
        <template #default="{ row: item }">
          {{ item.isRevolution ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template #default="{ row: item }">{{
      setupProjectStatusText[item.status]
    }}</template>
      </el-table-column>
      <el-table-column label="编制人" prop="createUserName"></el-table-column>
      <el-table-column label="下载" v-if="setupGroup == 1" align="left">
        <template #default="{ row: item }">
          <el-button type="text" icon="el-icon-download" @click="downloadWord(item)">申报表</el-button>

        </template>
      </el-table-column>
      <el-table-column label="操作" v-if="activeName != 'all'">
        <template #default="{ row: item }">
          <el-button-group size="small">
            <el-button type="primary" icon="el-icon-edit" @click="manage(item.id)" size="small" v-if="item.status == 0">
            </el-button>
            <el-popconfirm title="确认删除吗！？" @confirm="remove(item.id)" placement="top" v-if="item.status == 0">
              <template #reference>
                <el-button type="danger" icon="el-icon-delete" size="small"></el-button>
              </template>
            </el-popconfirm>

            <!-- (item.status == 2 && user.inRoles(roles.prov) && user.inRoles(roles.review))  || -->
            <el-button plain type="success" icon="el-icon-check" @click="manage(item.id)" size="small" v-if="item.status == 1 &&
      user.inRoles(roles.city) &&
      user.inRoles(roles.review)
      ">
            </el-button>
            <el-button plain type="success" icon="el-icon-check" @click="manage(item.id)" size="small" v-if="item.status >= 2 && item.status < 4 &&
      user.inRoles(roles.prov)
      ">
            </el-button>
            <!-- <el-button type="primary" icon="el-icon-download" @click="download(item)" size="small"></el-button> -->
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <Pager :pager="pager" @change="query"></Pager>

    <!-- <Manage :setting="manageSetting" @change="query"></Manage>
  <Detail :setting="detailSetting"></Detail> -->
    <!-- <Attach :setting="attachSetting"></Attach> -->
  </template>
  <el-dialog title="审核汇总申请" width="80%" v-model="showBackSubmitDialog" @close="beforeBackSubmitDialogClose">
    <el-form ref="refBackSubmitDialog" :model="backSubmitData" label-width="100px" :rules="{
      docId: [
        { required: true, message: '请选择批文！', trigger: 'blur' },
      ],
      reason: [
        { required: true, message: '请输入退回原因！', trigger: 'blur' },
      ],
      result: [
        { required: true, message: '请选择审核结果！', trigger: 'change' },
      ],
    }">
      <el-form-item label="批准文件" prop="docId">
        <officialDocSelectAndCreate :category="4" v-model="backSubmitData.docId"></officialDocSelectAndCreate>
      </el-form-item>
      <el-form-item label="审核" prop="result">
        <el-radio-group v-model="backSubmitData.result">
          <el-radio-button :label="true">通过</el-radio-button>
          <el-radio-button :label="false">退回</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注" v-if="backSubmitData.result">
        <el-input v-model="backSubmitData.reason" placeholder="备注" type="textarea" rows="3"></el-input>
      </el-form-item>
      <template v-else>
        <el-form-item label="退回原因" prop="reason">
          <el-input v-model="backSubmitData.reason" placeholder="退回原因" type="textarea" rows="3"></el-input>
        </el-form-item>
        <el-form-item label="退回项目" prop="projects">
          <el-table :data="backSubmitData.projects" @selection-change="handleBackSubmitDataSelectionChange">
            <el-table-column type="selection" width="55" />
            <el-table-column label="编号" prop="code"></el-table-column>
            <el-table-column label="地区" prop="areaName"></el-table-column>
            <el-table-column label="名称" prop="project_Name" width="400">
              <template #default="{ row: item }">
                {{ item.project_Name }}
              </template>
            </el-table-column>
            <el-table-column label="工程类型" prop="projectType">
              <template #default="{ row: item }">
                {{
      item.isSafeProject == 1
        ? dicLabel(item.safeProjectType, scheduleTypeDic)
        : dicLabel(item.projectType, scheduleTypeDic)
    }}
              </template>
            </el-table-column>
            <el-table-column label="文保单位" prop="heritageUnitName">
              <template #default="{ row: item }">
                <a href="javascript:void(0)" @click="lnkToDetail(item.heritageUnit_Id)">{{ item.heritageUnitName }}</a>
              </template>
            </el-table-column>
            <el-table-column label="文保单位类型" prop="heritageUnitClassName">
            </el-table-column>
            <el-table-column label="是否革命文物" prop="heritageUnit_Name">
              <template #default="{ row: item }">
                {{ item.isRevolution ? "是" : "否" }}
              </template>
            </el-table-column>
            <el-table-column label="状态">
              <template #default="{ row: item }">{{
      setupProjectStatusText[item.status]
    }}</template>
            </el-table-column>
            <el-table-column label="编制人" prop="createUserName"></el-table-column>
          </el-table>
        </el-form-item>
      </template>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showBackSubmitDialog = false" :loading="isActionLoading">取消</el-button>

        <el-popconfirm title="确认提交吗！？" @confirm="doBackSubmit" placement="top">
          <template #reference>
            <el-button type="primary" :loading="isActionLoading">提交</el-button>
          </template>
        </el-popconfirm>
      </span>
    </template>
  </el-dialog>
  </Base>
</template>

<script lang="ts">
import lnkToDetail from "@/views/heritageUnit/lnkToDetail"
import ShowRelBtn from "@/components/showRelBtn.vue";
import lnkToDocContent from "@/views/officialDoc/lnkToDocContent"
import officialDocSelectAndCreate from "@/views/officialDoc/offcialDocSelectAndCreate.vue";
import LogHistory from "@/views/shared/logHistory.vue";
import Pager from "@/views/layout/pager.vue";
import { ElMessageBox } from "element-plus";
import roles from "@/types/roles.ts";
// import Attach from "./attach.vue";

import { useCityRelTown } from "@/network/chinaArea";
import {
  defineComponent,
  inject,
  ref,
  onMounted,
  reactive,
  toRefs,
  toRef,
  watch,
} from "vue";
import {
  useQuery,
  useSetupProjectAction,
  useQueryFileSignManageActionHistory,
} from "@/network/setupProject";
import { openWindowWithToken } from "@/utils/util";
import {
  dicLabel,
  useSelectItemList,
  useSelectItemWithChildList,
  scheduleTypeDic,
  setupProjectYearBatch,
  setupProjectStatusText,
  setupProjectFileSignStatusText,
} from "@/network/lookUp.ts";
import Base from "@/views/layout/Base.vue";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  props: {
    setupGroup: {
      type: Number,
      default: 1
    }
  },
  components: {
    Base,
    Pager,
    LogHistory,
    officialDocSelectAndCreate,
    ShowRelBtn
    //, Attach
  },
  setup(props) {

    const $reload = inject<any>("$reload");
    watch(() => props.setupGroup, () => {
      $reload();
    })
    const prefix = ref(props.setupGroup == 1 ? "Prov" : "Nation");
    const showBackSubmitDialog = ref(false);
    const backSubmitData = reactive({
      result: true,
      id: null,
      reason: null,
      projectIds: [],
      projects: [],
    });

    const defaultYearBatch =
      setupProjectYearBatch.value &&
      setupProjectYearBatch.value.find((i) => {
        return i.isActive === true;
      })?.yearBatch;
    const downloadYearBatch = ref(defaultYearBatch);
    const user: any = inject("user");
    const activeName = ref(null);
    const router = useRouter();
    const route = useRoute();
    const signList = ref([]);
    const signListProjects = reactive({});
    const fileSignfilter = reactive({
      yearBatch: null,
      status: null,
      city: null,
      setupGroup: props.setupGroup
    });

    const filter = reactive({
      yearBatch: null,
      pageNum: 1,
      pageSize: 20,
      status: null,
      keyword: null,
      city: null,
      town: null,
      projectType: null,
      heritageUnitClass: null,
      isRevolution: null,
      isSafeProject: null,
      setupGroup: props.setupGroup
    });


    if (defaultYearBatch) {
      fileSignfilter.yearBatch = filter.yearBatch = defaultYearBatch;
    }

    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town"),
      null
    );

    const dicHeritageClass = useSelectItemList("HeritageClass");
    const manage = (id) => {
      router.push({ name: `ReportAndReview${prefix.value}SetupManage`, params: { id } });
    };
    const [isLoading, pagerData, query, pager] = useQuery(filter);

    const [
      isActionLoading,
      ,
      removeFn,
      ,
      ,
      queryFileSign,
      findFileSignProjects,
      backFileSign,
      deleteFileSign,
    ] = useSetupProjectAction();

    const refBackSubmitDialog = ref(null);

    const beforeBackSubmitDialogClose = () => {
      Object.assign(backSubmitData, {
        id: null,
        reason: null,
        projectIds: [],
        projects: [],
      });

      refBackSubmitDialog.value.clearValidate();
    };
    const doBackSubmit = () => {
      refBackSubmitDialog.value.validate((flag) => {
        if (flag) {
          backFileSign(backSubmitData.id, backSubmitData).then((p) => {
            showBackSubmitDialog.value = false;
            doQueryFileSign();
          });
        }
      });
    };
    const handleBackSubmitDataSelectionChange = (val) => {
      backSubmitData.projectIds = val.map((p) => p.id);
    };

    const backSubmit = (item) => {
      showBackSubmitDialog.value = true;
      backSubmitData.id = item.id;
      expandChange(item).then((p) => {
        backSubmitData.projects = signListProjects[item.id];
      });

      //console.log(backSubmitData.projects);
    };
    const remove = (id) => {
      removeFn(id).then(() => {
        query();
      });
    };

    const expandChange = (row) => {
      if (signListProjects[row.id] == null) {
        signListProjects[row.id] = [];
        return findFileSignProjects(row.id, { onlySelected: true }).then(
          (res) => {
            Object.assign(signListProjects[row.id], res);
            return signListProjects[row.id];
          }
        );
      } else {
        return Promise.resolve(signListProjects[row.id]);
      }
    };

    const createFileSign = () => {
      const params = {
        yearBatch: defaultYearBatch,
        signType: "计划书",
        applyUnitId: user.id,
        applyUnitName: user.name,
        redirect: `/ReportAndReview/${prefix.value}Setup?tab=apply`,
        setupGroup: null,
      };
      params.setupGroup = props.setupGroup;
      sessionStorage.setItem(
        "setupProjectFileSignMange_CreateProps",
        JSON.stringify(params)
      );
      router.push({
        name: "ReportAndReviewSetupFileSignManage",
        params,
      });
    };

    const showLogHistory = ref(false);
    const [logs, queryHistory] = useQueryFileSignManageActionHistory();

    const doQueryHistory = (id) => {
      showLogHistory.value = true;
      queryHistory(id);
    };

    const doQueryFileSign = () => {
      queryFileSign(fileSignfilter).then((p) => {
        signList.value = p.list;
      });
    };
    const doDeleteFileSign = (id) => {
      deleteFileSign(id).then(doQueryFileSign);
    };
    onMounted(() => {
      if (user.inRoles(roles.city)) {
        filter.status = 1;
        activeName.value = "1";
        filter.city = user.city_ID;
        //console.log(user.city_ID);
        // const stop = watch(dicCity, () => {
        //   filter.city = user.city_ID;
        //   stop();
        // });
      } else if (user.inRoles(roles.prov)) {
        activeName.value = "apply";
      } else {
        filter.status = 0;
        activeName.value = "0";
      }
      if (route.query.tab) {
        activeName.value = route.query.tab;
        let s = parseInt(activeName.value)
        if (s)
          filter.status = s
      }
      if (activeName.value == "apply") {
        if (user.inRoles(roles.prov, roles.city)) doQueryFileSign();
      } else {
        query();
      }
    });

    const downloadProvList = (yearBatch) => {
      openWindowWithToken(
        `/api/SetupProject/ExportFileSign${prefix.value}ListByYear/${yearBatch}`
      );
    };

    const downloadList = (item, flag) => {
      openWindowWithToken(
        `/api/SetupProject/ExportFileSign${prefix.value}List/${item.id}/${flag}`
      );
    };

    const downloadPackList = (yearBatch, signId = null) => {
      openWindowWithToken(
        `/api/SetupProject/download${prefix.value}Package/${yearBatch}/${signId ? signId : ''}`
      );
    }
    const downloadWord = (item) => {
      openWindowWithToken(`/api/SetupProject/ExportWord/${item.id}`);
    };

    const downloadListExcel = () => {
      openWindowWithToken(`/api/SetupProject/ExportProject${prefix.value}List`, filter);
    };
    return {
      lnkToDetail,
      prefix,
      lnkToDocContent,
      createFileSign,
      downloadProvList,
      setupProjectYearBatch,
      downloadYearBatch,
      downloadPackList,
      showLogHistory,
      doQueryHistory,
      logs,
      fileSignfilter,
      beforeBackSubmitDialogClose,
      refBackSubmitDialog,
      doBackSubmit,
      handleBackSubmitDataSelectionChange,
      showBackSubmitDialog,
      backSubmitData,
      backSubmit,
      pager,
      remove,
      downloadListExcel,
      downloadList,
      downloadWord,
      expandChange,
      doQueryFileSign,
      doDeleteFileSign,
      findFileSignProjects,
      signList,
      signListProjects,
      setupProjectStatusText,
      setupProjectFileSignStatusText,
      scheduleTypeDic,
      dicHeritageClass,
      activeName,

      dicCity,
      dicTown,

      dicLabel,
      isLoading,
      isActionLoading,
      user,
      roles,
      manage,
      query,
      pagerData,
      filter,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        query();
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        query();
      },
    };
  },
});
</script>
